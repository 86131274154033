import { useParams } from "react-router-dom";
import AwardCard from "../components/AwardCard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AwardSinglePage = () => {
  const { id } = useParams();
  const [awardSingleData, setAwardSingleData] = useState({});
  const { t } = useTranslation();

  const awardsData = t("awardsData", { returnObjects: true });

  useEffect(() => {
    const fetchData = awardsData[0]?.find((el) => String(el.id) === id);

    setAwardSingleData(fetchData);
  }, [id,t]);
  return (
    <section className="w-full flex  flex-col justify-center items-center px-4">
      <div className="w-full  md:w-[840px] md:h-[280px] mt-5 md:mt-16">
        <AwardCard {...awardSingleData} buttonView={false} awardSingle={true} />
        <div className="mt-5 md:mt-8 pb-10 md:pb-20">
          <h3 className="text-base md:text-lg font-semibold">{awardSingleData.descTitle}</h3>
          <p className="mt-2 text-sm md:text-base" dangerouslySetInnerHTML={{ __html: awardSingleData.desc || '' }}></p>
        </div>
      </div>
    </section>
  );
};

export default AwardSinglePage;
