import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [currentLanguage, setCurrenLanguage] = useState("az");
  const [activeId, setActiveId] = useState(1);

  const navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();
  const handleChangeLanguage = (e) => {
    const newLanguage = e.target.value;
    setCurrenLanguage(newLanguage);
    changeLanguage(newLanguage);
  };
  const links = t("links", { returnObjects: true });

  return (
    <header className="pt-3 xl:pt-0">
      <div className="flex justify-center items-center max-w-[1340px] mx-auto p-3 my-0 md:my-5">
        <div className="w-full flex justify-between  items-center">
          <div className="flex items-center gap-[430px]">
            <svg
            onClick={() => navigate('/')}
              width="121"
              height="56"
              viewBox="0 0 121 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_6488_6213)">
                <path
                  d="M8.20434 32.9292C6.82171 32.9292 5.65829 32.4582 4.71411 31.5162C3.80031 30.572 3.3293 29.4086 3.3293 27.9934C3.3293 26.5782 3.80031 25.4126 4.71411 24.4706C5.65829 23.5264 6.82171 23.0554 8.20434 23.0554C9.22233 23.0554 10.1318 23.3202 10.8654 23.8042C11.1671 22.6994 11.586 21.6467 12.1091 20.6504C10.9631 20.0535 9.6369 19.7214 8.23907 19.7214C5.87969 19.7214 3.89581 20.5072 2.29395 22.1134C0.720308 23.6848 -0.0654297 25.634 -0.0654297 27.9955C-0.0654297 30.3224 0.720308 32.2715 2.29395 33.8777C3.89581 35.4817 5.87969 36.2697 8.23907 36.2697C9.63907 36.2697 10.9588 35.9376 12.107 35.3363C11.5839 34.3422 11.165 33.2873 10.8633 32.1825C10.1144 32.66 9.20062 32.9292 8.20434 32.9292Z"
                  fill="#40C872"
                />
                <path
                  d="M53.9182 16.7C51.4329 16.4807 50.2066 17.5183 50.2066 19.8147V20.1294H53.9182V23.4004H50.2066V35.8572H46.8097V23.4004H44.4199V20.1294H46.8097V19.8147C46.8097 15.5062 49.2624 13.1468 53.9182 13.429V16.7Z"
                  fill="#40C872"
                />
                <path
                  d="M64.5471 16.7C62.0619 16.4807 60.8355 17.5183 60.8355 19.8147V20.1294H64.5471V23.4004H60.8355V35.8572H57.4386V23.4004H55.0488V20.1294H57.4386V19.8147C57.4386 15.5062 59.8913 13.1468 64.5471 13.429V16.7Z"
                  fill="#40C872"
                />
                <path
                  d="M67.9751 29.4389C68.4786 31.8287 70.3974 33.1506 73.0085 33.1506C74.8014 33.1506 76.1537 32.4907 77.0675 31.1992L79.8675 32.8358C78.3264 35.1323 76.0299 36.2653 72.9782 36.2653C70.4299 36.2653 68.3549 35.4795 66.7813 33.9385C65.2076 32.3648 64.4219 30.3831 64.4219 27.9933C64.4219 25.634 65.2076 23.6848 66.7509 22.1112C68.292 20.5071 70.3062 19.7192 72.7285 19.7192C75.025 19.7192 76.9134 20.5375 78.3915 22.1416C79.8696 23.7456 80.625 25.7273 80.625 28.0237C80.625 28.3688 80.5924 28.842 80.4991 29.4389H67.9751ZM77.1912 26.6715C76.7202 24.1233 74.9251 22.8014 72.6916 22.8014C70.176 22.8014 68.4135 24.2795 67.9425 26.6715H77.1912Z"
                  fill="#40C872"
                />
                <path
                  d="M85.684 29.4389C86.1876 31.8287 88.1064 33.1506 90.7175 33.1506C92.5104 33.1506 93.8627 32.4907 94.7765 31.1992L97.5764 32.8358C96.0354 35.1323 93.7389 36.2653 90.6871 36.2653C88.1389 36.2653 86.0639 35.4795 84.4902 33.9385C82.9166 32.3648 82.1309 30.3831 82.1309 27.9933C82.1309 25.634 82.9166 23.6848 84.4598 22.1112C86.0009 20.5071 88.0152 19.7192 90.4375 19.7192C92.734 19.7192 94.6223 20.5375 96.1005 22.1416C97.5786 23.7456 98.334 25.7273 98.334 28.0237C98.334 28.3688 98.3014 28.842 98.2081 29.4389H85.684ZM94.9002 26.6715C94.4292 24.1233 92.6341 22.8014 90.4006 22.8014C87.885 22.8014 86.1225 24.2795 85.6515 26.6715H94.9002Z"
                  fill="#40C872"
                />
                <path
                  d="M100.311 17.1407C99.4626 16.2595 99.4626 14.9398 100.311 14.0889C101.16 13.2077 102.545 13.2077 103.393 14.0889C104.242 14.9376 104.242 16.2595 103.361 17.1407C102.514 17.9894 101.193 17.9894 100.311 17.1407ZM100.155 35.8573V20.1295H103.552V35.8573H100.155Z"
                  fill="#40C872"
                />
                <path
                  d="M119.975 26.2005V35.8573H116.578V26.5456C116.578 24.1862 115.226 22.8969 113.055 22.8969C110.665 22.8969 109.059 24.3751 109.059 27.4269V35.8573H105.662V20.1295H109.059V22.1438C110.097 20.5397 111.701 19.7214 113.934 19.7214C117.552 19.7193 119.975 22.172 119.975 26.2005Z"
                  fill="#40C872"
                />
                <path
                  d="M27.9262 43.86C19.1768 43.86 12.0596 36.7428 12.0596 27.9934C12.0596 19.2439 19.1768 12.1267 27.9262 12.1267C36.6757 12.1267 43.7929 19.2439 43.7929 27.9934C43.7929 36.7428 36.6757 43.86 27.9262 43.86ZM27.9262 15.8579C21.2345 15.8579 15.7907 21.3016 15.7907 27.9934C15.7907 34.6852 21.2345 40.1289 27.9262 40.1289C34.618 40.1289 40.0618 34.6852 40.0618 27.9934C40.0618 21.3016 34.618 15.8579 27.9262 15.8579Z"
                  fill="#40C872"
                />
                <path
                  d="M47.1394 53.168C46.1446 53.168 45.359 52.7401 44.9155 51.9597H44.8901V53.1051H43.8447V43.6277H44.9472V47.4224H44.9725C45.4033 46.6798 46.1953 46.2582 47.1647 46.2582C48.8817 46.2582 50.0539 47.6427 50.0539 49.7131C50.0539 51.7898 48.8817 53.168 47.1394 53.168ZM46.943 47.2336C45.7518 47.2336 44.9408 48.2468 44.9408 49.7131C44.9408 51.1857 45.7518 52.1926 46.943 52.1926C48.1404 52.1926 48.9261 51.2234 48.9261 49.7131C48.9261 48.2091 48.1404 47.2336 46.943 47.2336Z"
                  fill="black"
                />
                <path
                  d="M51.4503 55.5657C51.3109 55.5657 51.1018 55.5468 50.9688 55.5216V54.5965C51.0955 54.6217 51.2729 54.6343 51.4186 54.6343C52.0522 54.6343 52.3943 54.3322 52.6731 53.5141L52.8062 53.1114L50.2781 46.3211H51.4503L53.3637 51.9912H53.3891L55.3025 46.3211H56.4619L53.7882 53.5204C53.2053 55.0937 52.6604 55.5657 51.4503 55.5657Z"
                  fill="black"
                />
                <path
                  d="M61.428 53.1051H60.2875V44.0241H65.8377V45.0436H61.428V48.165H65.4766V49.1719H61.428V53.1051Z"
                  fill="black"
                />
                <path
                  d="M66.9047 53.1051V46.3211H68.0071V53.1051H66.9047ZM67.4559 45.2261C67.0758 45.2261 66.759 44.9115 66.759 44.5339C66.759 44.15 67.0758 43.8417 67.4559 43.8417C67.8424 43.8417 68.1528 44.15 68.1528 44.5339C68.1528 44.9115 67.8424 45.2261 67.4559 45.2261Z"
                  fill="black"
                />
                <path
                  d="M69.4035 53.1051V46.3211H70.4426V47.4224H70.468C70.6707 46.7113 71.3233 46.2582 72.1216 46.2582C72.2547 46.2582 72.4511 46.2771 72.5461 46.3022V47.3847C72.4574 47.3469 72.166 47.3091 71.9759 47.3091C71.0889 47.3091 70.506 47.951 70.506 48.9076V53.1051H69.4035Z"
                  fill="black"
                />
                <path
                  d="M73.2456 48.2153C73.2456 47.07 74.291 46.2645 75.7736 46.2645C77.1612 46.2645 78.2129 47.0889 78.2573 48.2028H77.1992C77.1295 47.5672 76.5719 47.1707 75.7356 47.1707C74.8929 47.1707 74.329 47.5672 74.329 48.1587C74.329 48.6181 74.6965 48.9265 75.4822 49.1216L76.5212 49.3796C77.9025 49.7257 78.3967 50.204 78.3967 51.192C78.3967 52.3499 77.2752 53.168 75.6976 53.168C74.2277 53.168 73.1632 52.3751 73.0555 51.1983H74.1643C74.2784 51.8905 74.8422 52.2618 75.7736 52.2618C76.705 52.2618 77.2879 51.8779 77.2879 51.2675C77.2879 50.7892 77.0028 50.5375 76.2235 50.3361L75.026 50.0278C73.8285 49.7194 73.2456 49.1216 73.2456 48.2153Z"
                  fill="black"
                />
                <path
                  d="M79.7931 44.7919H80.8955V46.3211H82.226V47.2273H80.8955V51.299C80.8955 51.922 81.1616 52.2052 81.7445 52.2052C81.8776 52.2052 82.1437 52.1863 82.2197 52.1737V53.0988C82.0867 53.1302 81.7699 53.1554 81.5101 53.1554C80.2746 53.1554 79.7931 52.6457 79.7931 51.343V47.2273H78.8427V46.3211H79.7931V44.7919Z"
                  fill="black"
                />
                <path
                  d="M87.5089 53.1051H86.3684V44.0241H87.5089V53.1051Z"
                  fill="black"
                />
                <path
                  d="M92.6371 53.1051H91.4967V45.0436H88.5505V44.0241H95.5833V45.0436H92.6371V53.1051Z"
                  fill="black"
                />
                <path
                  d="M105.117 52.0856V53.1051H99.5673V44.0241H100.708V52.0856H105.117Z"
                  fill="black"
                />
                <path
                  d="M111.76 52.0856V53.1051H106.21V44.0241H107.35V52.0856H111.76Z"
                  fill="black"
                />
                <path
                  d="M116.236 53.2561C113.714 53.2561 112.117 51.4374 112.117 48.5615C112.117 45.7107 113.733 43.8731 116.236 43.8731C118.225 43.8731 119.739 45.0751 120.031 46.8875H118.878C118.586 45.6981 117.541 44.9178 116.236 44.9178C114.449 44.9178 113.289 46.3526 113.289 48.5615C113.289 50.7892 114.436 52.2115 116.242 52.2115C117.585 52.2115 118.548 51.5696 118.878 50.462H120.031C119.613 52.2555 118.257 53.2561 116.236 53.2561Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_6488_6213">
                  <rect width="120.031" height="56" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <ul className="hidden md:flex gap-10 text-[#888888]  text-sm md:text-base ">
              {links.map((el) => {
                return (
                  <li
                    onClick={() => setActiveId(el?.id)}
                    className={classNames({
                      " font-semibold text-black": el?.id === activeId,
                    })}
                    key={el.id}
                  >
                    <Link to={el.href}> {el.hrefName}</Link>{" "}
                  </li>
                );
              })}
            </ul>
          </div>
          <select
            className="block md:hidden pl-2 w-[55px]  border border-solid rounded-lg focus-visible:outline-none focus-visible:border-none"
            onChange={handleChangeLanguage}
            value={currentLanguage}
          >
            <option value={"az"}>AZ</option>
            <option value={"en"}>EN</option>
          </select>
          <div className="hidden md:flex  gap-4">
            <select
              className=" pl-2 w-[55px]   rounded-lg focus-visible:outline-none focus-visible:border-none"
              onChange={handleChangeLanguage}
              value={currentLanguage}
            >
              <option value={"az"}>Az</option>
              <option value={"en"}>En</option>
            </select>
            {/* <button className=" bg-[#40C872] px-4 py-2 text-sm font-medium rounded-[10px] font-['SanFrancisco',_sans-serif] text-white">
              Tərəfdaş ol
            </button> */}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center md:hidden mt-5">
        <ul className="flex gap-10 text-[#888888] text-sm md:text-base font-medium">
          {links.map((el) => {
            return (
              <li
                onClick={() => setActiveId(el?.id)}
                className={classNames({
                  " font-semibold text-black": el?.id === activeId,
                })}
                key={el.id}
              >
                <Link to={el.href}> {el.hrefName}</Link>{" "}
              </li>
            );
          })}
        </ul>
      </div>
    </header>
  );
};

export default Header;
