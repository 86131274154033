import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FirstAward from "../assets/a1f775994352a28da302a023bbe66147.png";
import SecondAward from "../assets/f5dfa207b20d6c21fe600720fd3a7401.jpeg";
import ThirdAward from "../assets/ae8b8c466cb0c7d4eab76ba05a76f803.png";

const iconMap = {
  FirstAward: FirstAward,
  SecondAward: SecondAward,
  ThirdAward: ThirdAward,
};

const AwardCard = ({
  id,
  image,
  title,
  awardName,
  awardSubName,
  classname,
  buttonView = true,
  awardSingle = false,
}) => {
  const { t } = useTranslation();


  return (
    <Link
      to={`/awards/${id}`}
      className={classNames(
        "h-[125px] w-full md:h-[320px] md:w-[1340px] bg-slate-600 relative rounded-[32px] block",
        { '!w-full' : awardSingle }
      )}
    >
      <img
        className="w-full h-full object-cover rounded-2xl md:rounded-[32px]"
        src={iconMap[image]}
        alt="firstslider"
      />
      <div
        className={classNames("absolute top-2 left-5 md:top-12 md:left-16", {
          "md:top-10 md:left-10": awardSingle,
        })}
      >
        <h3 className="text-base md:text-[40px] text-white font-semibold">{title}</h3>
        <p className={classNames("mt-2 md:mt-4 text-sm md:text-lg text-white" , {
          'md:mt-6' : awardSingle
        })}>{awardName}</p>
        {!awardSingle && (
          <p className="mt-6  text-sm text-white">{awardSubName}</p>
        )}
        {awardSingle && (
          <p className="mt-6 md:mt-[112px] text-xs md:text-sm text-white">{awardSubName}</p>
        )}
        {buttonView && (
          <button className="hidden md:block px-10 py-2 font-medium rounded-[10px] bg-white mt-16">
            {t("more")}
          </button>
        )}
      </div>
    </Link>
  );
};

export default AwardCard;
