import junctionLogo from "../../assets/junction.svg";
import adaLogo from "../../assets/ada.svg";
import kapitalLogo from "../../assets/kapitalbank.svg";
import pmcLogo from "../../assets/pmc.jpeg";
import xalqBankLogo from "../../assets/xalqBank.svg";
import gdcLogo from "../../assets/gdc.svg";
import atbLogo from "../../assets/atb.svg";
import { useTranslation } from "react-i18next";

const partnersImages = [
  { id: 1, image: adaLogo },
  { id: 2, image: xalqBankLogo },
  { id: 3, image: atbLogo },
  { id: 4, image: kapitalLogo },
  { id: 5, image: pmcLogo },
  { id: 6, image: gdcLogo },
  { id: 7, image: junctionLogo },
];

const PartnersSection = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-12 w-full">
      <h3 className=" text-4xl text-black font-semibold text-center">
        {t("partners")}{" "}
      </h3>

      <div className="mt-12">
        <div className="px-5 flex justify-between items-center gap-5 flex-col md:flex-row">
          {partnersImages?.slice(0, 4)?.map((el) => {
            return (
              <img
                className="w-[150px] h-[100px] object-contain"
                key={el.id}
                src={el.image}
                alt="logos"
              />
            );
          })}
        </div>
        <div className="mt-0 md:mt-20 w-full flex items-center justify-between md:justify-center gap-5 md:gap-36 flex-col md:flex-row">
          {partnersImages?.slice(4, 9)?.map((el) => {
            return (
              <img
                className="w-[150px] h-[100px] object-contain"
                key={el.id}
                src={el.image}
                alt="logos"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PartnersSection;
