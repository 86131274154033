import React, { useEffect, useRef, useState } from "react";
import "../scss/style.css";
import { toast } from "sonner";
import PartnersCard from "../components/PartnersCard/PartnersCard";
import { getListPartner } from "../helper/Http";
import PartnersCardSkeleton from "../Atoms/Skeleton";
import useDebounce from "../hook/useDebounce";
import Lottie from "react-lottie";
import animation from "../assets/animation.json";
import { useTranslation } from "react-i18next";
import QRcode from "../assets/QR_code.png";
import LeftArrow from "../assets/left-arrow.svg";
import RightArrow from "../assets/right-arrow.svg";
import CoffeinLogoImage from "../assets/mobile-cof.svg";
import LinkExternal from "../assets/link-external.svg";
import SearchLoop from "../assets/search-loupe.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import PartnerModal from "../components/PartnerModal";
import axios from "axios";
import PartnerModalMobile from "../components/PartnerModalMobile";
import PartnersSection from "../components/PartnersSection/PartnersSection";

const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const modalRef = useRef(null);
  const [orderModalView, setOrderModalView] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const [morePartners, setMorePartners] = useState(false);
  // const [currentLanguage, setCurrenLanguage] = useState("az");
  const { t } = useTranslation();
  // const handleChangeLanguage = (e) => {
  //   const newLanguage = e.target.value;
  //   setCurrenLanguage(newLanguage);
  //   changeLanguage(newLanguage);
  // };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    getListPartner({
      search: debouncedSearchTerm,
    })
      .then((res) => {
        setData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        toast.error("Xəta baş verdi");
      });
  }, [debouncedSearchTerm]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (orderModalView) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [partnerId, orderModalView]);

  const handleClickPartnerInfo = (id, orderModalView) => {
    setPartnerId(id);
    setOrderModalView(orderModalView);
  };
  return (
    <React.Fragment>
      <div
        className={
          "bg-[#ffffff] max-md:hidden w-[1512px] max-md:w-full  m-auto over"
        }
      >
        {orderModalView && (
          <PartnerModal
            ref={modalRef}
            id={partnerId}
            orderModalView={orderModalView}
            setOrderModalView={setOrderModalView}
          />
        )}

        <div className="flex flex-col gap-6 items-center justify-start ">
          <div
            className="text-center font-semibold-18-24-font-family text-semibold-18-24-font-size font-semibold leading-semibold-18-24-line-height font-semibold-18-24-font-weight relative w-[970px] font-['OpenSans-Regular',_sans-serif]"
            style={{ color: "linear-gradient(to left, #000000, #000000)" }}
          >
            {t("slogan")}
          </div>

          <h1
            className="text-center max-md:text-[24px] font-['OpenSans-Regular',_sans-serif] text-[64px] leading-[120%] font-semibold  w-[782px]"
            style={{
              background:
                "linear-gradient(0deg, #1111, #1111), linear-gradient(117.62deg, #40CC72 15.6%, #5B68F6 83.92%)",

              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {t("mainTitle", { brandName: "Coffeein" })}
          </h1>
        </div>

        <div className="flex max-w-[1340px] mx-auto p-3 gap-6 mt-14">
          <div className="bg-[#F7F7F7] rounded-3xl flex gap-8 p-12 w-1/2 h-[206px]">
            <div>
              <img src={QRcode} alt="qrlogo" />
            </div>
            <div>
              <h3 className="text-[#000] text-[28px] font-medium font-['OpenSans-Regular',_sans-serif]">
                {t("downloadCoffein")}
              </h3>
              <p className="text-[#888888] mt-1 text-xl font-['OpenSans-Regular',_sans-serif]">
                {t("unlimitedCoffee")}
              </p>
              <p className="text-[#000] text-base mt-4 font-['OpenSans-Regular',_sans-serif]">
                {t("downloadCoffeinText")}
              </p>
            </div>
          </div>
          <div className=" w-1/2 relative h-[206px]">
            <button className="custom-prev absolute z-10 left-2 top-1/2 -translate-y-1/2">
              <img src={LeftArrow} alt="left-arrow" />
            </button>
            <button className="custom-next absolute z-10 right-2 top-1/2 -translate-y-1/2">
              <img src={RightArrow} alt="left-arrow" />
            </button>
            <Swiper
              className="rounded-3xl"
              pagination={true}
              spaceBetween={10}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={{
                nextEl: ".custom-next",
                prevEl: ".custom-prev",
              }}
              modules={[Pagination, Navigation, Autoplay]}
            >
              <SwiperSlide>
                <div className="p-12 slider-award-image-first rounded-3xl">
                  <div>
                    <h3 className="text-[#fff] font-medium text-[28px] font-['OpenSans-Regular',_sans-serif]">
                      {t("sliderFirstText")}
                    </h3>
                    <h2 className="text-[#fff] text-xl mt-1 font-['OpenSans-Regular',_sans-serif]">
                      Invest Forum
                    </h2>
                    <p className="text-[#fff] text-base mt-4 font-['OpenSans-Regular',_sans-serif]">
                      Özbahçeci Invest Club
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-12 slider-award-image-second rounded-3xl">
                  <div>
                    <h3 className="text-[#fff] font-medium text-[28px] font-['OpenSans-Regular',_sans-serif]">
                      {t("sliderSecondText")}
                    </h3>
                    <h2 className="text-[#fff] text-xl mt-1 font-['OpenSans-Regular',_sans-serif]">
                      IDDA
                    </h2>
                    <p className="text-[#fff] text-base mt-4 font-['OpenSans-Regular',_sans-serif]">
                      IDDA Awards
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-12 slider-award-image-third rounded-3xl">
                  <div>
                    <h3 className="text-[#fff] font-medium text-[28px] font-['OpenSans-Regular',_sans-serif]">
                      {t("sliderThirdTextTitle")}
                    </h3>
                    <h2 className="text-[#fff] text-xl mt-1 font-['OpenSans-Regular',_sans-serif]">
                      Netty Awards
                    </h2>
                    <p className="text-[#fff] text-base mt-4 font-['OpenSans-Regular',_sans-serif]">
                      {" "}
                      {t("sliderThirdTextInfo")}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="max-w-[1340px] mx-auto p-3 mt-4">
          <h2 className="text-center max-md:text-[24px] my-2 font-['OpenSans-Regular',_sans-serif] text-[34px] leading-[120%] font-semibold  ">
            {t("descTitle")}
          </h2>
          <h2
            className="text-center text-gray-800 max-md:text-[12px] font-['OpenSans-Regular',_sans-serif]  mb-3 text-[14px] leading-[120%] font-semibold"
            dangerouslySetInnerHTML={{
              __html: t("cashbackDesc", { brandName: "Coffeein" }) || "",
            }}
          ></h2>
          <div className="flex justify-center items-center mt-6">
            <div className=" min-w-[480px] relative">
              <input
                type="text"
                name="search"
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t("search")}
                className=" border border-[#F5F5F5]  max-h-[44px] w-full py-[10px] px-4 outline-none font-['OpenSans-Regular',_sans-serif]  bg-[#F5F5F5] rounded-lg text-[#777]"
              />

              <img
                className="absolute right-4 top-[10px]"
                src={SearchLoop}
                alt="searchLogo"
              />
            </div>
          </div>
        </div>

        <div className="max-w-[1340px] mx-auto my-2 ">
          <div className="w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6">
            {loading && <PartnersCardSkeleton number={12} />}
            {data &&
              data
                ?.slice(0, 4)
                ?.map((el) => (
                  <PartnersCard
                    key={el?.id}
                    onClick={() => handleClickPartnerInfo(el?.id, true)}
                    ParnetrName={el?.name}
                    Address={el?.address}
                    Image={el?.icon}
                    Price={el?.cashback}
                    Rate={el?.rate}
                    Latitude={el?.lat}
                    Longtitude={el?.lng}
                  />
                ))}
          </div>
          {!loading && !morePartners && (
            <div className="flex   justify-center items-center mt-5  rounded-xl text-sm ">
              <button
                className="px-4 py-[10px] bg-[#F7F7F7]"
                onClick={() => setMorePartners(true)}
              >
                {t("allView")}
              </button>
            </div>
          )}
          {morePartners && (
            <div className="w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6">
              {data &&
                data
                  ?.slice(4, -1)
                  ?.map((el) => (
                    <PartnersCard
                      key={el?.id}
                      onClick={() => handleClickPartnerInfo(el?.id, true)}
                      ParnetrName={el?.name}
                      Address={el?.address}
                      Image={el?.icon}
                      Price={el?.cashback}
                      Rate={el?.rate}
                      Latitude={el?.lat}
                      Longtitude={el?.lng}
                    />
                  ))}
            </div>
          )}
          {!loading && morePartners && (
            <div className="flex   justify-center items-center mt-5  rounded-xl text-sm ">
              <button
                className="px-4 py-[10px] bg-[#F7F7F7]"
                onClick={() => setMorePartners(false)}
              >
                {t("hide")}
              </button>
            </div>
          )}
          <section className="hidden md:flex max-w-[1340px]  mx-auto">
            <PartnersSection />
          </section>
          <div className="flex justify-center">
            {data.length === 0 && (
              <div className="flex items-center">
                {" "}
                <Lottie options={defaultOptions} height={80} width={80} />
                <h1 className="text-center my-4 text-xl "> {t("notFound")}</h1>
              </div>
            )}
            {data && Object.keys(data).length === 0 && (
              <div className="flex items-center">
                {" "}
                <Lottie options={defaultOptions} height={120} width={120} />
                <h1 className="text-center my-4 text-2xl max-sm:text-md">
                  {t("notFound")}
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="hidden  max-md:flex flex-col justify-between items-center mx-4">
        <PartnerModalMobile
          ref={modalRef}
          id={partnerId}
          orderModalView={orderModalView}
          setOrderModalView={setOrderModalView}
        />

        {/* <div className=" h-[40px] mt-4 bg-[#ffffff] flex flex-col gap-6 items-center justify-start relative overflow-hidden">
          {" "}
          <select
            className="pl-2 w-[55px] h-10  border border-solid rounded-lg focus-visible:outline-none focus-visible:border-none"
            onChange={handleChangeLanguage}
            value={currentLanguage}
          >
            <option value={"az"}>AZ</option>
            <option value={"en"}>EN</option>
          </select>
        </div> */}
        <div
          className="text-center font-semibold-18-24-font-family my-4 text-semibold-18-24-font-size leading-semibold-18-24-line-height font-semibold-18-24-font-weight font-['OpenSans-Regular',_sans-serif]"
          style={{ color: "linear-gradient(to left, #000000, #000000)" }}
        >
          {t("slogan")}
        </div>
        <h1
          className="text-center max-md:text-[34px] text-semibold font-semibold font-['OpenSans-Regular',_sans-serif] text-[64px] leading-[140%]   max-w-[782px]"
          style={{
            background:
              "linear-gradient(0deg, #1111, #1111), linear-gradient(117.62deg, #40CC72 15.6%, #5B68F6 83.92%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          {t("mainTitle", { brandName: "Coffeein" })}
        </h1>
        <h1
          className="text-center max-md:text-[34px] font-['OpenSans-Regular',_sans-serif] text-[64px] leading-[140%] font-semibold  max-w-[782px]"
          style={{
            background:
              "linear-gradient(0deg, #1111, #1111), linear-gradient(117.62deg, #40CC72 15.6%, #5B68F6 83.92%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          {t("soon")}
        </h1>
        <a
          href="https://onelink.to/coffeein"
          className="p-5 mx-4 w-full  flex items-center justify-center rounded-2xl gap-4  bg-[#F7F7F7] mt-5"
        >
          <div>
            <img src={CoffeinLogoImage} alt="coffein-logo" />
          </div>
          <div>
            <h3 className="text-[20px] font-semibold">
              {" "}
              {t("downloadCoffein")}
            </h3>
            <p className="text-[#888888] text-sm">{t("unlimitedCoffee")}</p>
          </div>
          <div>
            <img src={LinkExternal} alt="link-external" />
          </div>
        </a>
        <div className="mx-4 w-full h-[140px] mt-5">
          <Swiper
            className="rounded-3xl mobile-swiper"
            pagination={true}
            loop={true}
            spaceBetween={10}
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <div className="p-5 slider-award-image-first rounded-3xl">
                <div>
                  <h3 className="text-[#fff] font-medium text-[20px] font-['OpenSans-Regular',_sans-serif]">
                    {t("sliderFirstText")}
                  </h3>
                  <h2 className="text-[#fff] text-sm mt-[2px] font-['OpenSans-Regular',_sans-serif]">
                    Invest Forum
                  </h2>
                  <p className="text-[#fff] text-base mt-2 font-['OpenSans-Regular',_sans-serif]">
                    Özbahçeci Invest Club
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-5 slider-award-image-second rounded-3xl">
                <div>
                  <h3 className="text-[#fff] font-medium text-[20px] font-['OpenSans-Regular',_sans-serif]">
                    {t("sliderSecondText")}
                  </h3>
                  <h2 className="text-[#fff] text-sm mt-[2px] font-['OpenSans-Regular',_sans-serif]">
                    IDDA
                  </h2>
                  <p className="text-[#fff] text-base mt-2 font-['OpenSans-Regular',_sans-serif]">
                    IDDA Awards
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-5 slider-award-image-third rounded-3xl">
                <div>
                  <h3 className="text-[#fff] font-medium text-[20px] font-['OpenSans-Regular',_sans-serif]">
                    {t("sliderThirdTextTitle")}
                  </h3>
                  <h2 className="text-[#fff] text-sm mt-[2px] font-['OpenSans-Regular',_sans-serif]">
                    Netty Awards
                  </h2>
                  <p className="text-[#fff] text-base mt-2 font-['OpenSans-Regular',_sans-serif]">
                    {t("sliderThirdTextInfo")}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="mt-4">
          <h2 className="text-center max-md:text-[24px] my-2 font-['OpenSans-Regular'] text-[34px] leading-[120%] font-semibold  ">
            {t("descTitle")}
          </h2>
          <h2
            className="text-center text-gray-800 max-md:text-[12px]  mb-3 text-[14px] leading-[120%] font-semibold font-['OpenSans-Regular',_sans-serif]"
            dangerouslySetInnerHTML={{
              __html: t("cashbackDesc", { brandName: "Coffeein" }) || "",
            }}
          ></h2>
        </div>
        <div className="w-full relative">
          <input
            type="text"
            name="search"
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("search")}
            className=" border border-[#F5F5F5]  max-h-[44px] w-full py-[10px] px-4 outline-none font-['OpenSans-Regular',_sans-serif]  bg-[#F5F5F5] rounded-lg text-[#777]"
          />
          <img
            className="absolute right-4 top-[10px]"
            src={SearchLoop}
            alt="searchLogo"
          />
        </div>

        <div className="max-w-[1340px] mx-auto my-2 ">
          <div className="w-full flex justify-between gap-5  flex-wrap">
            {loading && <PartnersCardSkeleton number={12} />}

            {data &&
              data
                ?.slice(0, 4)
                ?.map((el, index) => (
                  <PartnersCard
                    onClick={() => handleClickPartnerInfo(el?.id, true)}
                    ParnetrName={el?.name}
                    Address={el?.address}
                    Image={el?.icon}
                    Price={el?.cashback}
                    Rate={el?.rate}
                    Latitude={el?.lat}
                    Longtitude={el?.lng}
                    key={index}
                  />
                ))}
          </div>
          {!loading && !morePartners && (
            <div className="flex h-10   justify-center items-center mt-5  rounded-xl text-sm relative">
              <button
                className="absolute top-0 px-4 py-[10px] bg-[#F7F7F7]"
                onClick={() => setMorePartners(true)}
              >
                {t("allView")}
              </button>
            </div>
          )}
          {morePartners && (
            <div className="w-full flex justify-between gap-5  flex-wrap">
              {data &&
                data
                  ?.slice(4, -1)
                  ?.map((el) => (
                    <PartnersCard
                      key={el?.id}
                      onClick={() => handleClickPartnerInfo(el?.id, true)}
                      ParnetrName={el?.name}
                      Address={el?.address}
                      Image={el?.icon}
                      Price={el?.cashback}
                      Rate={el?.rate}
                      Latitude={el?.lat}
                      Longtitude={el?.lng}
                    />
                  ))}
            </div>
          )}
          {!loading && morePartners && (
            <div className="flex   justify-center items-center mt-5  rounded-xl text-sm ">
              <button
                className="px-4 py-[10px] bg-[#F7F7F7]"
                onClick={() => setMorePartners(false)}
              >
                {t("hide")}
              </button>
            </div>
          )}
          <div className="md:hidden">
            <PartnersSection />
          </div>
          <div className="flex justify-center mx-3">
            {" "}
            {data.length === 0 && (
              <div className="flex items-center">
                {" "}
                <Lottie options={defaultOptions} height={80} width={80} />
                <h1 className="text-center my-4 text-xl "> {t("notFound")}</h1>
              </div>
            )}
            {data?.length > 0 && Object.keys(data)?.length === 0 && (
              <div className="flex items-center">
                {" "}
                <Lottie options={defaultOptions} height={80} width={80} />
                <h1 className="text-center my-4 text-xl "> {t("notFound")}</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
